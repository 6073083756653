import { Controller } from "@hotwired/stimulus"
import React from 'react';
import {createRoot} from "react-dom/client";
import Uppy from '@uppy/core'
import AwsS3 from '@uppy/aws-s3';
import Filmstrip from "@/components/Filmstrip";

// Connects to data-controller="direct-upload"
export default class extends Controller {
  static values = {"workoutId": Number}
  static targets = ["progressContainer", "videoContainer", "progress", "progressLabel", "form", "input", "info"]

  uploadInProgress = false;

  connect() {
  }

  disconnect() {
    if (this.uppy) {
      this.uppy.cancelAll()
    }
  }

  // drop->direct-uploads#handleInputChange
  handleOnDrop(event) {
    event.preventDefault();
    // const { files } = event.dataTransfer;
    // Array.from(files).forEach((file) => this.uploadFile(file));
  }

  // change->direct-uploads#handleInputChange
  handleInputChange({ currentTarget }) {
    const { files } = currentTarget

    this.uploadInProgress = true;
    this.renderLocalPlayer(files);
    this.showInfo()
    const { dataset: { directUploadUrl } } = currentTarget

    this.uppy = new Uppy({
      allowMultipleUploads: false,
      // logger: this.debugLogger
    })
    .use(AwsS3, {
      shouldUseMultipart(file) {
        return true
      },
      companionUrl: '/'
    })
    .on('complete', this.complete.bind(this))
    .on('upload-progress', this.progress.bind(this))
    .on('upload-error', (file, error, response) => {
      console.error(`Error: ${error}`)
      console.error(`Response: ${response}`)
      const { name } = file;
      console.error(`Failed in uploading ${name}`);
      if (response && response.status === 413) {
        console.warning('Please check the file size');
      }
    });

    Array.from(files).forEach((file) => this.uploadFile(file));

    this.uppy.upload()

    const oldVidRepresentation = this.element.querySelector('#attached-video')
    if (oldVidRepresentation) {
      oldVidRepresentation.remove()
    }
  }

  confirmForProgress(event) {
    if (this.uploadInProgress) {
      const confirmed = window.confirm("Upload in progress! Are you sure you want to leave?");
      if (!confirmed) {
        event.preventDefault()
      }
    }
  }

  renderLocalPlayer(files) {
    const fileUrl = URL.createObjectURL(files[0])
    const fileType = files[0].type
    const el = document.createElement('div')
    el.setAttribute('id', 'local_player')
    this.videoContainerTarget.innerHTML = ''
    this.videoContainerTarget.insertAdjacentElement('beforeend', el)
    const root = createRoot(el);
    root.render(
        <Filmstrip vidSrc={fileUrl} vidType={fileType}/>
    );
  }

  uploadFile(file) {
    try {
      this.uppy.addFile({
        source: 'file input',
        name: file.name,
        type: file.type,
        data: file,
      });
    } catch (err) {
      if (err.isRestriction) {
        // handle restrictions
        console.log('Restriction error:', err);
      } else {
        // handle other errors
        console.error(err);
      }
    }
  }

  complete(result) {
    result.successful.forEach(file => {
      this.onUploadComplete(file)
    })
  }

  progress(file, progress) {
    const prog = `${Math.round((progress.bytesUploaded / progress.bytesTotal) * 100)}%`
    this.progressTarget.style = `width:${prog}`
    this.progressLabelTarget.innerText = `Uploading: ${prog}`
  }

  get debugLogger() {
    return {
      debug: (...args) => console.debug(`[Uppy]`, ...args),
      warn: (...args) => console.warn(`[Uppy]`, ...args),
      error: (...args) => console.error(`[Uppy]`, ...args),
    }
  }

  showInfo() {
    if (this.infoTarget) {
      this.progressContainerTarget.innerHTML = ""
      this.progressContainerTarget.insertAdjacentHTML('afterbegin', this.infoTarget.innerHTML)
      this.progressContainerTarget.removeAttribute('hidden')
      this.videoContainerTarget.removeAttribute('hidden')
      this.progressTarget.style = 'width:0%'
      this.progressLabelTarget.innerText = 'Uploading: 0%'
    }
  }

  // Uploader calls this callback and passes back the blob
  onUploadComplete(blob) {
    this.inputTarget.value = null

    // Add an appropriately-named hidden input to the form with a value of blob.signed_id
    this.createOrUpdateBlobInput(blob)

    this.formTarget.requestSubmit()

    this.uploadInProgress = false;
    this.progressTarget.style = 'width:100%'
    this.progressTarget.classList.add('animate-indeterminate-progress')
    this.progressLabelTarget.innerText = 'Preparing...'
  }

  // Shrine expects a JSON representation of the direct-uploaded file with the following metadata format:
  // {
  //   "id": "302858ldg9agjad7f3ls.jpg",
  //   "storage": "cache",
  //   "metadata": {
  //     "size": 943483,
  //     "filename": "nature.jpg",
  //     "mime_type": "image/jpeg",
  //   }
  // }

  createOrUpdateBlobInput(blob) {
    const uploadedFile = {
      'id': blob.s3Multipart.key.replace('cache/', ''),
      'storage': 'cache',
      'metadata': {
        'size': blob.size,
        'filename': blob.name,
        'mime_type': blob.type
      }
    }
    const hiddenField = document.getElementById('blobVal') || document.createElement('input')
    hiddenField.setAttribute("type", "hidden")
    hiddenField.setAttribute("value", JSON.stringify(uploadedFile))
    hiddenField.setAttribute("id", "blobVal")
    hiddenField.name = 'source_video'
    this.formTarget.appendChild(hiddenField)
  }

}
