import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="livestream"
export default class extends Controller {
  static values = {poll: Boolean, workoutId: Number}

  connect() {
    super.connect()
    if (this.pollValue) {
      this.startPolling()
    }
  }

  disconnect() {
    super.disconnect()
    this.stopPolling()
  }

  startPolling() {
    this.stopPolling()
    window.poll_interval = setInterval(this.fetchLivestreamStatus.bind(this), 2000)
  }

  async fetchLivestreamStatus() {
    let response = await fetch(`/admin/workouts/livestream_status?id=${this.workoutIdValue}`)
    const text = await response.text()
    Turbo.renderStreamMessage(text)
  }

  stopPolling() {
    clearInterval(window.poll_interval);
  }
}
