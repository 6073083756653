import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="tag"
export default class extends Controller {
  static targets = ["entry", "list", "hiddenInput"]
  static values = {"preventDelete": Boolean}
  connect() {
    const tagsString = this.hiddenInputTarget.value
    if (tagsString.length > 0) {
      this.buildTagsFromString(tagsString)
    }
  }

  buildTagsFromString(tagsString) {
    const tags = tagsString.split("|,|")
    tags.forEach((tag) => {
      if (tag.length > 0) this.insertTag(tag)
    })
  }

  entrySubmit(e) {
    e.preventDefault()
    const val = this.entryTarget.value
    if (val.length > 0) {
      this.insertTag(val);
      this.addToForm(val);
    }
    this.entryTarget.value = null
  }

  addToForm(val) {
    this.hiddenInputTarget.value += `${val}|,|`
  }

  insertTag(val) {
    if (this.preventDeleteValue) {
      this.listTarget.innerHTML += this.nonDeletableTagHTML(val)
    } else {
      this.listTarget.innerHTML += this.tagHTML(val)
    }
  }

  remove(e) {
    e.preventDefault()
    const val = e.target.parentElement.getElementsByClassName('tag-val')[0].textContent + '|,|'
    e.target.parentElement.remove()
    this.removeFromForm(val);
  }

  removeFromForm(val) {
    let hiddenVal = this.hiddenInputTarget.value
    hiddenVal = hiddenVal.replace(val, '')
    this.hiddenInputTarget.value = hiddenVal
  }

  nonDeletableTagHTML(val) {
    return `<div class="m-1 inline-block h-8 rounded-lg bg-gray-100 outline outline-gray-200">
            <div class="inline-block px-4 py-2 font-medium tag-val text-md">${val}</div>
           </div>`
  }

  tagHTML(val) {
    return `<div class="m-1 inline-block h-8 rounded-lg bg-gray-100 outline outline-gray-200">
            <button data-action="tag#remove" type="button" class="h-8 w-6 rounded-l-lg bg-indigo-600 text-white mr-0.5 text-md">✕</button>
            <div class="inline-block pr-4 font-medium tag-val pl-2.5 text-md">${val}</div>
           </div>`
  }
}
