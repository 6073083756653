import { Controller } from "@hotwired/stimulus"
import Datepicker from 'flowbite-datepicker/Datepicker';

// Connects to data-controller="datepicker"
export default class extends Controller {

  connect() {
    new Datepicker(this.element, {
      autohide: true
    });
  }

}
