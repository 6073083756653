import { Controller } from "@hotwired/stimulus"
import React from 'react';
import {createRoot} from 'react-dom/client';

import Filmstrip from '../components/Filmstrip';

// Connects to data-controller="filmstrip"
export default class extends Controller {
  static values = {
    src: String,
    dir: Array,
  };

  connect() {
      const root = createRoot(this.element);
      root.render(<Filmstrip vidSrc={this.srcValue} thumbs={this.dirValue} />);
  }
}
