import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="post-type"
export default class extends Controller {
  static targets = ["audioSection"]

  connect() {
    this.toggleAudioSection()
  }

  toggleAudioSection() {
    const postType = document.querySelector('input[name="post[post_type]"]:checked').value
    const audioSection = document.getElementById("audio-section")

    if (postType === "podcast") {
      audioSection.classList.remove("hidden")
    } else {
      audioSection.classList.add("hidden")
    }
  }
}
