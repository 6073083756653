import { Controller } from "@hotwired/stimulus"
import React from 'react';
import {createRoot} from 'react-dom/client';
import ImageInput from '../components/ImageInput';

// Connects to data-controller="profile-image"
export default class extends Controller {
  static values = {inputName: String, inputAccepts: String, maxHeight: Number, ref: String, filename: String}

  connect() {
    const root = createRoot(this.element);
    root.render(<ImageInput inputName={this.inputNameValue} inputAccepts={this.inputAcceptsValue} maxHeight={this.maxHeightValue} imgRef={this.refValue} file={this.filenameValue} isProfile={true}/>);
  }
}
