import { Controller } from "@hotwired/stimulus"

// Applies a video timestamp to POST requests that create segments and exercises

// Connects to data-controller="timestamper"
export default class extends Controller {
  static values = {href: String}

  connect() {
  }

  stamp() {
    const timestamp = Math.floor(window.hoverTime ?? window.playbackTime)
    if (this.hrefValue.includes('?')) {
      this.element.setAttribute('href', `${this.hrefValue}&timestamp=${timestamp}`)
    } else {
      this.element.setAttribute('href', `${this.hrefValue}?timestamp=${timestamp}`)
    }
  }

}
