import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="post-visibility"
export default class extends Controller {
  static targets = ["radio", "comingSoon"]

  connect() {
  }

  radioChanged(e) {
    if (e.currentTarget.value === 'scheduled' && e.currentTarget.checked) {
      this.comingSoonTarget.removeAttribute('hidden')
    } else {
      this.comingSoonTarget.setAttribute('hidden', 'hidden')
    }
  }

  // If the user selects a date, we assume they're expecting it to be a
  // scheduled post, so we'll select the "Schedule" radio button
  dateChanged(e) {
    for (const radio of this.radioTargets) {
      radio.removeAttribute('checked')
      if (radio.value === 'scheduled') {
        radio.checked = true
        this.comingSoonTarget.removeAttribute('hidden')
      }
    }
  }

}
