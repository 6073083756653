import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="routine-tabs"
export default class extends Controller {
  static targets = ["addButton", "videoSequence"]

  connect() {
  }

  addGroup(e) {
    this.scrollOnConnect = true
  }

  addItem(e) {
  }

  videoSequenceTargetConnected(target) {
    if (this.scrollOnConnect) {
      target.scrollIntoView({behavior: "smooth"})
      this.scrollOnConnect = false
    }
  }

  browse_badge(event){

  }
}
