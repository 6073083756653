// app/javascript/controllers/location_controller.js

import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static values = {id: Number}

  connect() {
    this.getLocation();
  }

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async function(location) {
        console.log("Latitude:", location.coords.latitude);
        console.log("Longitude:", location.coords.longitude);
        console.log("Accuracy:", location.coords.accuracy);
      
        this.fetchLocation(location)
      }, this.showError );
      
    } else {
      this.fetchLocation(null)
      console.error("Geolocation is not supported by this browser.");
    }
  }

  fetchLocation(location){
    const csrfToken = document.querySelector("[name='csrf-token']").content

    const latitude = location ? location.coords.latitude : null;
    const longitude = location ? location.coords.longitude : null;
    let response = fetch(`/workouts/near_by_workouts?latitude=${latitude}&longitude=${longitude}`, {
        method: "POST",
        headers: {
        "X-CSRF-Token": csrfToken,
        "Content-Type": "application/json"
      }
      })
      const text = response.text()
      Turbo.renderStreamMessage(text)
  }
  

  showError(error) {
    console.log("error")
    this.fetchLocation(null)
    switch(error.code) {
      case error.PERMISSION_DENIED:
        console.log("User denied the request for Geolocation.")
        break;
      case error.POSITION_UNAVAILABLE:
        console.log("Location information is unavailable.")
        break;
      case error.TIMEOUT:
        console.log("The request to get user location timed out.")
        break;
      case error.UNKNOWN_ERROR:
        console.log("An unknown error occurred.")
        break;
    }
  }
}