import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="scroll-container"
export default class extends Controller {
  static targets = ["scrollContainer", "scrollLeft", "scrollRight", "item"]

  connect() {
    this.setupScrollIndicators()
  }

  setupScrollIndicators() {
    this.containerOverflown = this.isOverflown(this.scrollContainerTarget)
    if (this.containerOverflown) {
      this.scrollLeftTarget.style.display = 'flex'
      this.scrollRightTarget.style.display = 'flex'
      this.containerScrolled(null)
      this.containerWidth = this.scrollContainerTarget.clientWidth
      this.fullScroll = this.scrollContainerTarget.scrollWidth
    } else {
      this.scrollLeftTarget.style.display = 'none'
      this.scrollRightTarget.style.display = 'none'
    }
  }

  isOverflown(element) {
    return element.scrollWidth > element.clientWidth
  }

  setIndicatorActive(target, active) {
    if (active) {
      target.classList.add('bg-indigo-600')
      target.classList.remove('bg-indigo-100')
    } else {
      target.classList.remove('bg-indigo-600')
      target.classList.add('bg-indigo-100')
    }
  }

  containerScrolled(e) {
    if (this.containerOverflown) {
      const pos = this.scrollContainerTarget.scrollLeft
      if (pos < 10) {
        // Fully scrolled left
        this.setIndicatorActive(this.scrollLeftTarget, false)
        this.setIndicatorActive(this.scrollRightTarget, true)
      } else {


        if (pos + this.containerWidth > this.fullScroll - 10) {
          // Fully scrolled right
          this.setIndicatorActive(this.scrollLeftTarget, true)
          this.setIndicatorActive(this.scrollRightTarget, false)
        } else {
          // Scrolled in middle somewhere
          this.setIndicatorActive(this.scrollLeftTarget, true)
          this.setIndicatorActive(this.scrollRightTarget, true)
        }
      }
    }
  }

  scrollToEnd() {
    this.scrollContainerTarget.scroll({top: 0, left: this.fullScroll - this.containerWidth, behavior: 'smooth' });
  }

  scrollToBeginning() {
    this.scrollContainerTarget.scroll({top: 0, left: 0, behavior: 'smooth'})
  }

  itemTargetConnected() {
    this.setupScrollIndicators()
  }

}
