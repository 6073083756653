import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="dropdown"
export default class extends Controller {

  static values = {remainOnSelect:Boolean }
  static targets = ["dropdownButton", "dropdownList"]

  connect() {
  }

  toggle(event) {
    event.preventDefault();
    if (this.isClosed()) {
      this.openDropdown(event)
    } else {
      this.closeDropdown(null)
    }
  }

  isClosed() {
    return this.dropdownListTarget.dataset.stateValue !== "true"
  }

  openDropdown(event) {
    this.dropdownListTarget.dataset.stateValue = "true";
    this.dropdownListTarget.classList.remove("animate-dropdown-close");
    this.dropdownListTarget.classList.add("animate-dropdown-open");
  }

  closeDropdown(event) {
    if (this.isClosed()) {
      return;
    }

    if (event) {
      // If user clicks the button to open the dropdown we don't want to immediately close it
      if (this.dropdownButtonTarget === event.target || this.dropdownButtonTarget.contains(event.target)) {
        return;
      }
      if (this.remainOnSelectValue && this.dropdownListTarget.contains(event.target)) {
        return;
      }
    }
    this.dropdownListTarget.dataset.stateValue = "false";
    this.dropdownListTarget.classList.remove("animate-dropdown-open");
    this.dropdownListTarget.classList.add("animate-dropdown-close");
  }
}
