import { Controller } from "@hotwired/stimulus"
import videojs from "video.js";
import 'video.js/dist/video-js.css';

// Connects to data-controller="video-player"
export default class extends Controller {
  static values = {hlsSrc: String, dashSrc: String}

  connect() {
    if (this.element.innerHTML.length === 0) {
      this.element.innerHTML = `<video-js id="vid1"></video-js>`

      this.jsPlayer = videojs('vid1', {
        controls: false,
        autoplay: true,
        muted: true,
        preload: 'auto',
        loop: true
      });

      this.jsPlayer.src([
        {type: 'application/x-mpegURL', src: '//d12gbrsuow9d7j.cloudfront.net/4249354513774609147_1699412803/video.m3u8'},
          // {type: 'video/mp4', src: '//vjs.zencdn.net/v/oceans.mp4'},
          // {type: 'video/ogg', src: '//vjs.zencdn.net/v/oceans.ogv'}
      ]);
    }
  }

  disconnect() {
    this.jsPlayer.dispose()
  }
}
