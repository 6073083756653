import { Controller } from "@hotwired/stimulus"
import React from 'react';
import {createRoot} from 'react-dom/client';
import ImageInput from '../components/ImageInput';

// A component that provides a stylized file input (with "image/*" filter) and displays a preview
// of the selected file. A `data-image-file-input-name-value="name"` prop should be provided in
// order to set the name prop on the input for file submission.

// Connects to data-controller="image-file"
export default class extends Controller {
  static values = {inputName: String, inputAccepts: String, maxHeight: Number, ref: String, filename: String}

  connect() {
    const root = createRoot(this.element);
    root.render(<ImageInput inputName={this.inputNameValue} inputAccepts={this.inputAcceptsValue} maxHeight={this.maxHeightValue} imgRef={this.refValue} file={this.filenameValue}/>);
  }
}
