// WorkoutVideo.jsx
import React, { useRef, useEffect } from 'react';
import videojs from "video.js";

const LivePreviewVideo = ({vidSrc, show, vidType = 'application/x-mpegURL' }) => {
    const videoRef = useRef(null);

    useEffect(() => setup(), []);

    const setup = () => {
        if (show) {
            if (window.jsPreviewPlayer != null && window.jsPreviewPlayer.player() != null) {window.jsPreviewPlayer.dispose()}

            window.jsPreviewPlayer = videojs('previewPlayer', {
                controls: true,
                autoplay: true,
                muted: true,
                preload: 'auto',
                loop: true,
                enableDocumentPictureInPicture: true
            })

            window.jsPreviewPlayer.src([
                {type: vidType, src: vidSrc},
            ])
        }

        startPolling()
    }

    const startPolling = () => {
        stopPolling()
        window.poll_interval = setInterval(poll, 5000)
    }

    const poll = () => {
        if (!show) return

        if (window.jsPreviewPlayer.liveTracker.isLive()) {
            stopPolling()
        } else {
            window.jsPreviewPlayer.pause()
            window.jsPreviewPlayer.load()
            window.jsPreviewPlayer.play()
        }
    }

    const stopPolling = () => {
        clearInterval(window.poll_interval);
    }

    return (
        <div className="relative">
            <div className="w-full aspect-w-16 aspect-h-9 bg-black">
                { show ? (
                    <video-js id="previewPlayer" ref={videoRef}></video-js>
                ) : (
                    <div></div>
                )}
            </div>
        </div>
    );
};

export default LivePreviewVideo;
