import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="routine-index-tabs"
export default class extends Controller {
  static targets = ["routineTab"]

  selectedTab = null;

  connect() {
  }

  routineTabTargetConnected() {
    this.selectedTab = this.routineTabTargets[0]
    this.routineTabTargets.forEach((tab) => {
      tab.classList.remove("bg-org1", "text-white")
    })
    this.selectedTab.classList.add("bg-org1", "text-white")
  }

  selectTab(event) {
    if (event.target === this.selectedTab) {
      return
    }

    this.selectedTab = event.target
    this.routineTabTargets.forEach((tab) => {
      if (tab === this.selectedTab) {
        tab.classList.add("bg-org1", "text-white")
      } else {
        tab.classList.remove("bg-org1", "text-white")
      }
    })
  }

}