import { Controller } from "@hotwired/stimulus"
import tippy from "tippy.js";
import 'tippy.js/dist/tippy.css';

// A tooltip implementation using tippy.js
// To use on any html element, simply add the 2 data properties:
// data-controller="tooltip" data-tooltip-message-value="Message to be displayed in tooltip"

// Connects to data-controller="tooltip"
export default class extends Controller {
  static values = {message: String}; // We could receive a config object here, to customize the tooltip

  connect() {
    this.tippyInstance = tippy(this.element, {
      content: this.messageValue,
      theme: 'charcoal',
    });
  }

  disconnect() {
    this.tippyInstance.destroy();
  }
}
