// Filmstrip.jsx
import React, { useState, useRef, useEffect } from 'react';
import videojs from "video.js";

const Filmstrip = ({vidSrc, thumbs = [], vidType = 'application/x-mpegURL'}) => {
    const [currentTime, setCurrentTime] = useState(0);
    const videoRef = useRef(null);
    const filmstripRef = useRef(null);
    const playbackCursorRef = useRef(null);
    const [ignoreMouseMove, setIgnoreMouseMove] = useState(true);
    const [cursorX, setCursorX] = useState(0);
    const [duration, setDuration] = useState(0)
    const [width, _setWidth] = useState(0)
    const widthRef = useRef(width)
    const setWidth = data => {
        widthRef.current = data
        _setWidth(data)
    }

    useEffect(() => setup(), []);

    const setup = () => {
        window.hoverTime = 0

        document.addEventListener('mousedown', e => setIgnoreMouseMove(false))
        document.addEventListener('mouseup', e => setIgnoreMouseMove(true))

        const timelineRect = filmstripRef.current.getBoundingClientRect()
        setWidth(timelineRect.width)

        if (window.jsPlayer) {window.jsPlayer.dispose()}

        window.jsPlayer = videojs('vid1', {
            controls: true,
            autoplay: true,
            muted: true,
            preload: 'auto',
            loop: false,
            enableDocumentPictureInPicture: true
        })

        window.jsPlayer.src([
            {type: vidType, src: vidSrc},
        ])

        window.jsPlayer.on('timeupdate', function() {
            timeUpdate(this.currentTime(), this.duration())
        })

        window.jsPlayer.on('loadedmetadata', function() {
            setDuration(this.duration())
        })

    }

    const handleTimelineHover = (e) => {
        e.stopPropagation()
        e.preventDefault()
        if (ignoreMouseMove) return

        window.jsPlayer.pause()

        setTimeAndMarkerForMouseEvent(e)
    }

    const setTimeAndMarkerForMouseEvent = (e) => {
        const timelineRect = filmstripRef.current.getBoundingClientRect()
        const offsetX = e.clientX - timelineRect.left
        const percentage = offsetX / width
        const newTime = (duration * percentage)
        if (!isNaN(newTime) && newTime !== currentTime && percentage <= 1 && percentage >= 0) {
            window.hoverTime = newTime
            setCurrentTime(newTime)
            window.jsPlayer.currentTime(newTime)
            setCursorX(offsetX)
        }
    }

    const timeUpdate = (time, duration) => {
        window.hoverTime = time
        setCurrentTime(time)
        if (duration > 0) {
            setCursorX(widthRef.current * (time / duration))
        }
    }

    const timeMMSS = (seconds) => {
        if (isNaN(seconds)) return
        // Using Date as a shorthand way to get a nice HH:MM:SS formatted string from seconds
        var date = new Date(0);
        date.setSeconds(seconds);
        if (currentTime < 3600) {
            return date.toISOString().substring(14, 19);
        } else {
            return date.toISOString().substring(11, 19);
        }
    }

    const filmstripArrayCount = () => {
        const arrayLengthCapped = Math.min(thumbs.length - 2, 12)
        return new Array(Math.max(0, arrayLengthCapped))
    }

    const onMouseUp = (e) => {
        setIgnoreMouseMove(true)
    }

    const onMouseDown = (e) => {
        setIgnoreMouseMove(false)
        setTimeAndMarkerForMouseEvent(e)
    }

    const cursorMargin = () => {
        if (cursorX < 130) {
            return '-ml-12'
        } else if (cursorX >= width - 130) {
            return '-ml-[11.15em]'
        } else {
            return '-ml-28'
        }
    }

    return (
        <div className="mt-6 bg-gray-100 p-2 select-none">
            <div className="relative">

                <div className="flex justify-center">
                    <video-js id="vid1" class="h-[21rem] w-[37rem]" ref={videoRef}></video-js>
                </div>

                <div ref={filmstripRef} className="flex h-32 w-full cursor-pointer pt-4"
                     onMouseMove={(e) => handleTimelineHover(e)}
                     onMouseUp={(e)=> onMouseUp(e)}
                     onMouseDown={(e)=> onMouseDown(e)}>
                    {filmstripArrayCount().fill(0).map((_, index) => (
                    <div key={index + 1} className="w-12 flex-auto overflow-hidden h-22">
                        <img draggable="false" src={thumbs[index + 1]} alt={`Video thumbnail`}/>
                    </div>
                    ))}

                    <div ref={playbackCursorRef} className="absolute bottom-8 h-20 bg-black w-1.5"
                         style={{left: cursorX}}>
                        <div className={`mt-12 ${cursorMargin()} flex`}>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                     className="w-10 h-10 mt-2.5">
                                    <path fillRule="evenodd"
                                          d="M7.72 12.53a.75.75 0 0 1 0-1.06l7.5-7.5a.75.75 0 1 1 1.06 1.06L9.31 12l6.97 6.97a.75.75 0 1 1-1.06 1.06l-7.5-7.5Z"
                                          clipRule="evenodd"/>
                                </svg>
                            </div>

                            <div className="border-2 border-black bg-gray-100 w-96 rounded-lg">
                                <div
                                    className="mt-2 p-4 pt-2 w-36 text-center text-lg text-black font-semibold ">{timeMMSS(currentTime)}&nbsp;
                                    <span className="font-normal text-sm">/&nbsp;{timeMMSS(duration ?? 0)}</span></div>
                            </div>

                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                     className="w-10 h-10 mt-2.5">
                                    <path fillRule="evenodd"
                                          d="M16.28 11.47a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 0 1-1.06-1.06L14.69 12 7.72 5.03a.75.75 0 0 1 1.06-1.06l7.5 7.5Z"
                                          clipRule="evenodd"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Filmstrip;
