// LandingVideo.jsx
import React, { useState, useRef, useEffect } from 'react';
import videojs from "video.js";

const LandingVideo = ({vidSrc, thumbs = [], vidType = 'application/x-mpegURL' }) => {
    const videoRef = useRef(null);

    useEffect(() => setup(), []);
    const setup = () => {
        if (window.jsPlayer) {window.jsPlayer.dispose()}

        window.jsPlayer = videojs('vid1', {
            controls: false,
            autoplay: true,
            muted: true,
            preload: 'auto',
            loop: true,
            playsinline: true,
            enableDocumentPictureInPicture: true
        })

        window.jsPlayer.src([
            {type: vidType, src: vidSrc},
        ])
    }

    return (
          <div>
                <div className="w-full aspect-w-16 aspect-h-9">
                        <video-js id="vid1" ref={videoRef}></video-js>
                </div>
            </div>

    );
};

export default LandingVideo;
