/**
 * ReactJS implementation of http://jsfiddle.net/LvsYc/
 * @uses Foundation 6
 * @see http://stackoverflow.com/questions/4459379/preview-an-image-before-it-is-uploaded
 */

import React, {useState, useId} from 'react';

const FileInput = ({inputName, inputAccepts, maxHeight, file, fileRef}) => {
    const [filename, setFilename] = useState(file);
    const inputId = useId();
    const filenameId = useId();
    const maximumHeight = maxHeight > 0 ? maxHeight : 999

    const DownloadButton = () => {
        if (fileRef.length > 0) {
            return (
                <div className='flex items-center'>
                    <a href={fileRef}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                             stroke="currentColor" className="w-5 h-5">
                            <path strokeLinecap="round" strokeLinejoin="round"
                                  d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"/>
                        </svg>
                    </a>
                </div>
            )
        } else {
            return (
                <div/>
            )
        }
    }

    const NameLabel = () => {
        if (filename.length > 0) {
            return (
                <div className="flex">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 mr-1">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                    </svg>
                    {filename}
                </div>
            )
        }
    }


    const FileContainer = () => {
        if (filename.length > 0) {
            return (
                <div id={filenameId} className="flex justify-between items-center rounded-md bg-gray-100 p-3">
                    <NameLabel/>
                    <DownloadButton/>
                </div>
            )
        } else {
            return (
                <div/>
                )

        }
    }

    const readURI = (e) => {
        if (e.target.files && e.target.files[0]) {
            let reader = new FileReader();
            reader.onload = function (ev) {
                setImageURI(ev.target.result);
            }.bind(this);
            reader.readAsDataURL(e.target.files[0]);
            setFilename(e.target.files[0].name)
        }
    }

    return (
        <div className="flex flex-col pt-2 gap-y-2">
            <FileContainer filename={filename}/>

            <label htmlFor={inputId} className="w-24 rounded-lg bg-indigo-600 px-5 py-2 text-center text-sm font-semibold text-white shadow-sm hover:cursor-pointer hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                Browse
            </label>

            <input id={inputId} name={inputName} type="file" className="hidden w-1 h-1 m-0 p-0" accept={inputAccepts}
                   onChange={(event) => {
                       readURI(event)
                   }}/>
        </div>
    )
}

export default FileInput;