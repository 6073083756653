import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {timeout: Number}

  connect() {
    setTimeout(() => {
      this.dismiss();
    }, this.timeoutValue || 5000);
  }

  dismiss() {
    this.element.classList.add('transform', 'opacity-0', 'transition', 'duration-500');
    setTimeout(() => this.element.remove(), 500)
  }
}