import { Controller } from "@hotwired/stimulus"
import React from 'react';
import {createRoot} from 'react-dom/client';
import LandingVideo from '../components/LandingVideo';

// Connects to data-controller="workout_video"
export default class extends Controller {
  static values = {
    src: String,
    dir: Array,
  };

  connect() {
      const root = createRoot(this.element);
      root.render(<LandingVideo vidSrc={this.srcValue} thumbs={this.dirValue} />);
  }
}
