import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="collapsible"
export default class extends Controller {

  static values = {state: Boolean}
  static targets = ["expandButton", "collapseButton", "collapsibleContainer"]

  connect() {
  }

  toggleCollapsibleView(event) {
    event.preventDefault();
    if (!this.state) {
      this.expandView(event)
    } else {
      this.collapseView(null)
    }

    // this.collapsibleContainerTarget.classList.toggle("hidden");
    // this.collapsibleContainerTarget.classList.toggle("opacity-0");

    this.expandButtonTarget.classList.toggle("hidden")
    this.collapseButtonTarget.classList.toggle("hidden")
  }

  expandView(event) {
    this.state = true;
    this.collapsibleContainerTarget.classList.remove("animate-collapsible-view-collapse");
    this.collapsibleContainerTarget.classList.add("animate-collapsible-view-expand");

  }

  collapseView(event) {
    const optionalView = this.collapsibleContainerTarget
    if (!this.state) {
      return;
    }

    if (event) {
      // If user clicks the button to open the dropdown we don't want to immediately close it
      if (this.collapsibleContainerTarget.contains(event.target)) {
        return;
      }
      if (this.collapsibleContainerTarget.contains(event.target)) {
        return;
      }
    }
    this.state = false;
    this.collapsibleContainerTarget.classList.remove("animate-collapsible-view-expand");
    this.collapsibleContainerTarget.classList.add("animate-collapsible-view-collapse");

  }
}
