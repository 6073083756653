import { Controller } from "@hotwired/stimulus"
import MusicProvider from '../MusicProvider';
import debounce from "lodash.debounce";
import { Base64 } from 'js-base64';

// Connects to data-controller="music-api"
export default class extends Controller {
  static values = {token: String}

  initialize(){
    this.searchKeyUp = debounce(this.searchKeyUp, 500).bind(this);
  }

  connect() {
    let musicProvider = MusicProvider.sharedProvider();
    musicProvider.configure(this.tokenValue);
    this.musicInstance = musicProvider.getMusicInstance();
  }

  // Gets debounced in initialize()
  async searchKeyUp(e) {
    let ul = e.target.parentElement.querySelector('ul')
    ul.innerHTML = ''
    if (e.target.value.length !== 1) {
      const res = await this.search(e.target.value)

      if (res.songs && res.songs.data.length > 0) {
        ul.insertAdjacentHTML('beforeend', `<div class="border-b  border-gray-300">
        <li class="group relative cursor-default blue-text select-none py-2 pl-3 pr-9 text-gray-900 hover:text-white hover:bg-indigo-600" tabindex="-1">
          <button type="button" data-modal-target="showModalButton" data-action="click->modal#presentModal" class="text-blue-500 font-bold">
            ADD SONG MANUALLY +
          </button>
        </li>
        </div>`)
        for (const song of res.songs.data) {
          ul.insertAdjacentHTML('beforeend', this.songListItem(song))
        }
        this.openDropdown(ul)
      }
    }
  }

  async search(val) {
    return await this.musicInstance.api.search(val, { type: 'stations', limit: 15, offset: 0 })
  }

  openDropdown(elem) {
    elem.classList.remove("animate-dropdown-close");
    elem.classList.add("animate-dropdown-open");
    elem.dataset.stateValue = "true"
  }

  closeDropdown(elem) {
    elem.classList.remove("animate-dropdown-open");
    elem.classList.add("animate-dropdown-close");
    elem.dataset.stateValue = "false"
  }

  async selectSong(e) {
    const currentTarget = e.currentTarget
    const songId = currentTarget.dataset.songId
    if (!songId) { return }

    const songJSON = await this.musicInstance.api.song(songId, {include: 'artists'})
    const songBase64 = Base64.encode(JSON.stringify(songJSON))
    const container = currentTarget.closest('div.music-container')
    this.setSongHiddenInput(container, songBase64)
    this.setSongTextInput(container, songJSON)
  }

  setSongHiddenInput(container, songBase64) {
    const hiddenInput = container.querySelector('input[type="hidden"]')
    if (hiddenInput) {
      hiddenInput.value = songBase64
    } else {
      container.insertAdjacentHTML('afterbegin', this.songInputHTML(container, songBase64))
    }
  }

  setSongTextInput(container, songJSON) {
    const songName = `${songJSON.attributes.name} - ${songJSON.attributes.artistName}`
    const textInput = container.querySelector('input[type="text"]')
    textInput.value = "\u2713 " + songName
    textInput.className += ' text-indigo-600'
  }

  songListItem(song) {
    const name = song.attributes.name
    const artist = song.attributes.artistName

    return `<li data-action="click->music-api#selectSong click->music-api#removeSongName" data-song-id=${song.id} title="${name} - ${artist}" class="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 hover:text-white hover:bg-indigo-600" id="option-0" role="option" tabindex="-1">
      <div class="flex">
          <span class="truncate">${name}</span>
          <span class="ml-3 truncate text-gray-400">${artist}</span>
        </div>
    </li>`
  }

  setMusicValue(event) {
    const activityContainer = event.currentTarget.closest('.activity-container')
    const musicContainer = activityContainer.querySelector('.music-container')
    const songNameContainer = activityContainer.querySelector('.manual-song-name')
    const songArtistContainer = activityContainer.querySelector('.manual-song-artist')
    const inputVal = musicContainer.querySelector('input[type="hidden"]')
    if (inputVal){
      inputVal.value = ""
    }
    const songName = `${songNameContainer.value} - ${songArtistContainer.value}`
    const textInput = musicContainer.querySelector('input[type="text"]')
    textInput.value = "\u2713 " + songName
    textInput.className += ' text-indigo-600'
  }

  removeSongName(event) {
    const activityContainer = event.currentTarget.closest('.activity-container')
    const songNameContainer = activityContainer.querySelector('.manual-song-name')

    if (songNameContainer){
      songNameContainer.value = ""
    }
  }

  songInputHTML(container, songBase64) {
    const inputName = container.dataset['inputName']
    return `<input type="hidden" name=${inputName} value=${songBase64} />`
  }

}
