import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="modal"
export default class extends Controller {

  static values = {state: Boolean}
  static targets = ["showModalButton", "modalContainer"]

  connect() {
  }

  presentModal(event) {
    this.stateValue = true;
    this.modalContainerTarget.classList.remove("animate-fade-out");
    this.modalContainerTarget.classList.add("animate-fade-in");
  }

  dismissModal(event) {
    if (!this.stateValue) {
      return;
    }
    if (event) {
      event.preventDefault()
    }
    this.stateValue = false;
    this.modalContainerTarget.classList.remove("animate-fade-in");
    this.modalContainerTarget.classList.add("animate-fade-out");
  }

  dismissOutsideModal(event) {
    if (!this.stateValue) {
      return;
    }
    if (event) {
      if (!event.target.matches('#outsideModalId')){
        return;
      }
    }
    this.stateValue = false;
    this.modalContainerTarget.classList.remove("animate-fade-in");
    this.modalContainerTarget.classList.add("animate-fade-out");
  }
}
