import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin-users"
export default class extends Controller {
  static targets = ['password', 'passwordConfirm']

  connect() {
  }

  input(e) {
    let pw = this.passwordTarget;
    let pwc = this.passwordConfirmTarget;

    if (pw.value.length < 8) {
      pw.setCustomValidity('Must be at least 8 characters');
    } else {
      pw.setCustomValidity('');
    }

    if (pwc.value.length < 8) {
      pwc.setCustomValidity('Must be at least 8 characters');
    } else {
      if (pw.value !== pwc.value) {
        pwc.setCustomValidity('Does not match password');
      } else {
        pwc.setCustomValidity('');
      }
    }
  }

}
