import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="schedule-day"
export default class extends Controller {
  static targets = ["workoutSelect"]

  connect() {
  }

  updatedType(e) {
    if (e.target.value === 'rest_day') {
      this.workoutSelectTarget.setAttribute('disabled', '')
      this.workoutSelectTarget.removeAttribute('required')
    } else {
      this.workoutSelectTarget.setAttribute('required', '')
      this.workoutSelectTarget.removeAttribute('disabled')
    }
  }
}
