// WorkoutVideo.jsx
import React, { useState, useRef, useEffect } from 'react';
import videojs from "video.js";

const WorkoutVideo = ({vidSrc, thumbs = [], vidType = 'application/x-mpegURL' }) => {
    const [currentTime, setCurrentTime] = useState(0);
    const videoRef = useRef(null);
    const filmstripRef = useRef(null);
    const playbackCursorRef = useRef(null);
    const [ignoreMouseMove, setIgnoreMouseMove] = useState(true);
    const [cursorX, setCursorX] = useState(0);
    const [duration, setDuration] = useState(0)
    const [width, _setWidth] = useState(0)
    const widthRef = useRef(width)
    const setWidth = data => {
        widthRef.current = data
        _setWidth(data)
    }

    useEffect(() => setup(), []);
    const setup = () => {
        window.hoverTime = 0
        document.addEventListener('mousedown', e => setIgnoreMouseMove(false))
        document.addEventListener('mouseup', e => setIgnoreMouseMove(true))

        const timelineRect = filmstripRef.current.getBoundingClientRect()
        setWidth(timelineRect.width)

        if (window.jsPlayer) {window.jsPlayer.dispose()}

        window.jsPlayer = videojs('vid1', {
            controls: true,
            autoplay: true,
            muted: true,
            preload: 'auto',
            loop: false,
            enableDocumentPictureInPicture: true
        })

        window.jsPlayer.src([
            {type: vidType, src: vidSrc},
        ])

        window.jsPlayer.on('timeupdate', function() {
            timeUpdate(this.currentTime(), this.duration())
        })

        window.jsPlayer.on('loadedmetadata', function() {
            setDuration(this.duration())
        })

    }

    const handleTimelineHover = (e) => {
        e.stopPropagation()
        e.preventDefault()
        if (ignoreMouseMove) return

        window.jsPlayer.pause()

        setTimeAndMarkerForMouseEvent(e)
    }

    const setTimeAndMarkerForMouseEvent = (e) => {
        const timelineRect = filmstripRef.current.getBoundingClientRect()
        const offsetX = e.clientX - timelineRect.left
        const percentage = offsetX / width
        const newTime = (duration * percentage)
        if (!isNaN(newTime) && newTime !== currentTime && percentage <= 1 && percentage >= 0) {
            window.hoverTime = newTime
            setCurrentTime(newTime)
            window.jsPlayer.currentTime(newTime)
            setCursorX(offsetX)
        }
    }

    const timeUpdate = (time, duration) => {
        window.hoverTime = time
        setCurrentTime(time)
        if (duration > 0) {
            setCursorX(widthRef.current * (time / duration))
        }
    }

    const timeMMSS = (seconds) => {
        if (isNaN(seconds)) return
        // Using Date as a shorthand way to get a nice HH:MM:SS formatted string from seconds
        var date = new Date(0);
        date.setSeconds(seconds);
        if (currentTime < 3600) {
            return date.toISOString().substring(14, 19);
        } else {
            return date.toISOString().substring(11, 19);
        }
    }

    const filmstripArrayCount = () => {
        const arrayLengthCapped = Math.min(thumbs.length - 2, 12)
        return new Array(Math.max(0, arrayLengthCapped))
    }
    const onMouseUp = (e) => {
        setIgnoreMouseMove(true)
    }

    const onMouseDown = (e) => {
        setIgnoreMouseMove(false)
        setTimeAndMarkerForMouseEvent(e)
    }

    const cursorMargin = () => {
        if (cursorX < 130) {
            return '-ml-12'
        } else if (cursorX >= width - 130) {
            return '-ml-[11.15em]'
        } else {
            return '-ml-28'
        }
    }

    return (
          <div className="relative">
						<div className="w-full aspect-w-16 aspect-h-9">
								<video-js id="vid1" ref={videoRef}></video-js>
						</div>

						<div ref={filmstripRef} className=""
							onMouseMove={(e) => handleTimelineHover(e)}
							onMouseUp={(e)=> onMouseUp(e)}
							onMouseDown={(e)=> onMouseDown(e)}>
						</div>
					</div>

    );
};

export default WorkoutVideo;
