import { Controller } from "@hotwired/stimulus"
import React from 'react';
import {createRoot} from 'react-dom/client';
import LivePreviewVideo from '../components/LivePreviewVideo';

// Connects to data-controller="live-preview"
export default class extends Controller {
  static values = {
    src: String,
    show: Boolean
  };

  connect() {
    const root = createRoot(this.element);
    root.render(<LivePreviewVideo vidSrc={this.srcValue} show={this.showValue} />);
  }
}
