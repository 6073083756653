import { Controller } from "@hotwired/stimulus"
import tippy from "tippy.js";

// Connects to data-controller="clipboard"
export default class extends Controller {
  static values = {copytext: String}

  connect() {
  }

  copyText() {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(this.copytextValue);
    }

    tippy(this.element, {
      content: 'Copied!',
      delay: [0, 1000],
      onHidden(instance) {
        instance.destroy()
      }
    }).show();
  }
}
