/**
 * ReactJS implementation of http://jsfiddle.net/LvsYc/
 * @uses Foundation 6
 * @see http://stackoverflow.com/questions/4459379/preview-an-image-before-it-is-uploaded
 */

import React, {useState, useId} from 'react';

const ImageInput = ({inputName, inputAccepts, maxHeight, imgRef, isProfile}) => {
    const [imageURI, setImageURI] = useState(imgRef);
    const imageId = useId();
    const inputId = useId();
    const maximumHeight = maxHeight > 0 ? maxHeight : 999

    const ImageView = ({imageURI}) => {
        if (imageURI.length > 0) {
            return (
                <div className="">
                    <img id={imageId} alt="selected image"
                         className="border border-gray-300"
                         src={imageURI} style={{maxHeight: maximumHeight + 'px'}}
                         onError={({ currentTarget }) => {
                             currentTarget.hidden = true
                         }}></img>
                </div>
            )
        } else if (isProfile) {
            return (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true"
                     className="h-12 w-12 text-gray-300">
                    <path fillRule="evenodd"
                          d="M18.685 19.097A9.723 9.723 0 0021.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 003.065 7.097A9.716 9.716 0 0012 21.75a9.716 9.716 0 006.685-2.653zm-12.54-1.285A7.486 7.486 0 0112 15a7.486 7.486 0 015.855 2.812A8.224 8.224 0 0112 20.25a8.224 8.224 0 01-5.855-2.438zM15.75 9a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
                          clipRule="evenodd"></path>
                </svg>
            )
        } else {
            return (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true"
                     className="mx-auto h-12 w-12 text-gray-300">
                    <path fillRule="evenodd"
                          d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z"
                          clipRule="evenodd"></path>
                </svg>
            )
        }
    }

    const readURI = (e) => {
        if (e.target.files && e.target.files[0]) {
            let reader = new FileReader();
            reader.onload = function (ev) {
                setImageURI(ev.target.result);
            }.bind(this);
            reader.readAsDataURL(e.target.files[0]);
        }
    }

    return (
        <div className="flex flex-col pt-2 gap-y-2">
            <ImageView imageURI={imageURI}/>

            <label htmlFor={inputId}
                   className="w-24 rounded-lg bg-indigo-600 px-5 py-2 text-center text-sm font-semibold text-white shadow-sm hover:cursor-pointer hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                Browse
            </label>

            <input id={inputId} name={inputName} type="file" className="hidden w-1 h-1 m-0 p-0" accept={inputAccepts}
                   onChange={(event) => {
                       readURI(event)
                   }}/>
        </div>
    )
}

export default ImageInput;