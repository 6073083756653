import { Controller } from "@hotwired/stimulus"
import debounce from "lodash.debounce";

// Connects to data-controller="filters"
export default class extends Controller {
  static values = {endpoint: String, state: Boolean}
  static targets = ["option", "filterForm", "sortInput", "sortTypeInput", "pillInput", "sortOption", 'searchForm', 'filterBackground']

  initialize(){
    this.searchKeyUp = debounce(this.searchKeyUp, 500).bind(this);
  }

  connect() {
  }

  toggleClass(link) {
    this.sortOptionTargets.forEach((el) => {
      el.classList.remove("active-link")
      el.classList.add("inactive-link")
    })

    link.classList.remove("inactive-link")
    link.classList.add("active-link")
  }

  runFilters () {
    this.filterFormTarget.requestSubmit();
    // if(this.optionTargets) {
    //   this.toggleFilterView()
    // }
  }

  toggleFilterView() {
    const filterView = this.filterBackgroundTarget
    let pillsCount = 0
    this.optionTargets.forEach((checkbox) => {
      if (checkbox.checked) {
        pillsCount ++
      }
    })
    if (pillsCount >= 1) {
      filterView.classList.remove("bg-transparent")
      filterView.classList.add("bg-gray-100")
    } else {
      filterView.classList.remove("bg-gray-100")
      filterView.classList.add("bg-transparent")
    }
  }

  removePill (e) {
    const pill = e.currentTarget
    this.optionTargets.forEach((checkbox) => {
      if (checkbox.id === pill.dataset.filterId) {
        checkbox.checked = false
      }
    })
    this.filterFormTarget.requestSubmit();
    this.toggleFilterView()

    // console.log("pillinput =" + e.parent)
    // if(e.tagName.toLowerCase() === 'button') {
    //   this.optionTargets.forEach((checkbox) => {
    //     if(checkbox.id === e.target.id) {
    //       console.log("button id  =" + e.target.id + "check box id = " + checkbox.id )
    //       checkbox.checked = false
    //     }
    //   })
    //   this.filterFormTarget.requestSubmit();
    //   this.toggleFilterView()
    // } else {
    //     this.removePill(e.parent)
    // }
  }

  setSort (e) {
    e.preventDefault()

    this.sortInputTarget.value = e.target.dataset.sortValue
    if (e.target.dataset.sortTypeValue !== undefined ){
      this.sortTypeInputTarget.value = e.target.dataset.sortTypeValue
    }
    this.filterFormTarget.requestSubmit()
    const link = e.currentTarget
    this.toggleClass(link)
    const sortButton = document.getElementById("sort-option_label")
    if (sortButton) {
      sortButton.textContent = " " + link.textContent
    }
  }

  // Gets debounced in initialize()
  searchKeyUp(e) {
    if (e.target.value.length !== 1) {
      this.runFilters()
    }
  }
}

