import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="routine-tabs"
export default class extends Controller {
  static targets = ["routineTab", "addButton", "tabSelector"]
  static values = {"routineId": Number}

  connect() {
  }

  setOrder() {
    let baseUrl = this.addButtonTarget.dataset.addTabUrl
    const count = this.routineTabTargets.length
    const routId = this.routineIdValue
    this.addButtonTarget.setAttribute('href', `${baseUrl}?count=${count}${routId > 0 ? `&id=${routId}` : ''}`)
  }

  routineTabTargetConnected(e) {
    this.syncTabSelects()
  }

  syncTabSelects() {
    for (let tab of this.routineTabTargets) {
      let tabName = tab.getElementsByClassName('tab-name')[0].value
      for (let select of this.tabSelectorTargets) {
        // Get the <select>'s <option> that represents the routine tab
        let opt = select.querySelector(`option[value='${tab.dataset.tabId}']`)
        // If <option> exists update the name, if it doesn't (because we just fetched it
        // from /add_tab) then add a new <option> for it
        if (opt === undefined || opt === null) {
          opt = document.createElement("option");
          opt.text = tabName;
          opt.value = tab.dataset.tabId
          select.add(opt)
        } else {
          opt.text = tabName
        }
        window.opt = opt
      }
    }
  }

  updateName(e) {
    this.syncTabSelects()
  }

}
