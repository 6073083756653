import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="sidenav"
export default class extends Controller {
  static targets = ["link"]

  connect() {
    // this.toggleClass(this.activeLink)
  }

  toggleClass(link) {
    this.linkTargets.forEach((el) => {
      el.classList.add("inactive-sidenav")
      el.classList.remove("active-sidenav")
    })

    link.classList.remove("inactive-sidenav")
    link.classList.add("active-sidenav")
  }

  // get activeLink() {
  //   return this.linkTargets.find((el) => el.classList.contains("active-sidenav"))
  // }

  linkClick(event) {
    // event.preventDefault()
    const link = event.currentTarget
    this.toggleClass(link)
  }
}