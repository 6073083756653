import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="navbar"
export default class extends Controller {
  static values  = { state: Boolean }
  static targets = [ "nav", "navButton" ]

  connect() {
    this.setupMobileButton()
  }

  selectNavButton(event) {
    this.navButtonTargets.forEach(function(el) {
      el.classList.remove("active-nav")
      el.classList.add("inactive-nav")
    })
    event.target.classList.remove("inactive-nav")
    event.target.classList.add("active-nav")
  }

  setupMobileButton() {
    const mobileMenuButton = document.querySelector(
        "button[aria-controls='mobile-menu']"
    );
    const mobileMenu = document.getElementById("mobile-menu");

    if (mobileMenuButton && mobileMenu) {
      mobileMenuButton.addEventListener("click", function () {
        const expanded = mobileMenuButton.getAttribute("aria-expanded") === "true";

        // Toggle the aria-expanded attribute
        mobileMenuButton.setAttribute("aria-expanded", !expanded);
        // Toggle the hidden class to show/hide the mobile menu
        mobileMenu.classList.toggle("hidden");
      });
    }
  }

  fullReload(e) {
    this.navTarget.removeAttribute('data-turbo-permanent');
  }
}
