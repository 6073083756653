import { Controller } from "@hotwired/stimulus"
import debounce from "lodash/debounce";

export default class extends Controller {
  static targets = ["copyright", "socialLink", "pageLink", "form", "socialDropdown", "socialUrl", "pageText", "pageUrl"];

  connect() {
    this.updateCopyright = debounce(this.updateCopyright, 500);
    this.updateSocialLinkUrl = debounce(this.updateSocialLinkUrl, 500);
    this.updatePageLinkUrl = debounce(this.updatePageLinkUrl, 500);
  }

  updateCopyright(event) {
    let text = event.target.value;

    fetch(`/admin/footer_links/copyright_update`, {
      method: 'PATCH', // Use PATCH since you are updating the resource
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
      body: JSON.stringify({ copyright_text: text })
    })
    .then(response => {
      if (response.ok) {
        return response.text();
      }
      throw new Error('Network response was not ok.');
    })
    .then(turboStreamHTML => {
      Turbo.renderStreamMessage(turboStreamHTML); // TurboFrame will be updated here
    })
    .catch(error => console.error('There was a problem with the fetch operation:', error));
  }

  validateSocialForm(event) {
    const socialPlatformSelected = this.socialDropdownTarget.value !== "";
    const urlFilled = this.socialUrlTarget.value.trim() !== "";

    if (socialPlatformSelected && urlFilled) {
      this.formTarget.requestSubmit();
    } else {
      this.formTarget.querySelector("button[type='submit']").disabled = true;
    }
  }

  validatePageForm(event) {
    const textFilled = this.pageTextTarget.value.trim() !== "";
    const urlFilled = this.pageUrlTarget.value.trim() !== "";

    if (urlFilled && textFilled) {
      this.formTarget.requestSubmit();
    } else {
      this.formTarget.querySelector("button[type='submit']").disabled = true;
    }
  }

  updateSocialLinkUrl(event) {
    if (this.socialLinkId && event.target.value) {
      let url = event.target.value;

      fetch(`/admin/footer_links/footer_link_update`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        },
        body: JSON.stringify({ link_id: this.socialLinkId, url: url })
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to update URL');
        }
        return response.json();
      })
      .then(data => {
        console.log("URL updated successfully:", data);
      })
      .catch(error => console.error('Error:', error));
    }
  }
}
