import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="color-list"
export default class extends Controller {

  static targets = ["hiddenColorValue", "selectedColorName", "selectedColorValue"]

  connect() {
  }
  
  setValue(event) {
    event.preventDefault()
    var element = event.currentTarget
    this.hiddenColorValueTarget.value = element.dataset.colorId
    this.selectedColorValueTarget.style.backgroundColor = "#" + element.dataset.colorValue 
    this.selectedColorNameTarget.innerHTML = element.dataset.colorName
  }
}
