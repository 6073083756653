import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="timestamp"
export default class extends Controller {
  connect() {
    // Add an event listener to the timestamp field for changes
    this.element.addEventListener("focusout", this.updateTimestamp.bind(this));
  }

  updateTimestamp() {
    // Save the current cursor position
    const cursorPosition = this.getCursorPosition();

    // Get the entered value
    let inputValue = this.element.value;

    // Add default values and colons if missing
    inputValue = this.addDefaultValuesAndColons(inputValue);

    // Split the value using colons
    const [hours, minutes, seconds] = inputValue.split(':');

    // Ensure the values are within valid ranges
    let updatedHours = isNaN(hours) ? 0 : Math.min(99, Math.max(0, Math.floor(hours)));
    let updatedMinutes = isNaN(minutes) ? 0 : Math.min(59, Math.max(0, Math.floor(minutes)));
    let updatedSeconds = isNaN(seconds) ? 0 : Math.min(59, Math.max(0, Math.floor(seconds)));

    // Update the value of the timestamp field
    let value = ('0' + updatedHours).slice(-2) + ':' + ('0' + updatedMinutes).slice(-2) + ':' + ('0' + updatedSeconds).slice(-2);

    let hiddenTimestampField = document.querySelector(`#${this.element.id.replace('_display_', '_')}`);

    if (hiddenTimestampField) {
      let secondsOnly = Math.floor(this.convertTimeToSeconds(value));
      if (jsPlayer !== null && jsPlayer.duration()){
        if (jsPlayer.duration() < secondsOnly){
          secondsOnly = Math.floor(jsPlayer.duration())
        }
      }
      hiddenTimestampField.value = secondsOnly;

      // Update the value of the timestamp field with HH:MM:SS format
      this.element.value = this.secondsToHHMMSS(secondsOnly);
    }

    // Restore the cursor position
    this.setCursorPosition(cursorPosition);
  }

  convertTimeToSeconds(timeString) {
    const [hours, minutes, seconds] = timeString.split(':').map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  }

  secondsToHHMMSS(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);

    return ('0' + hours).slice(-2) + ':' + ('0' + minutes).slice(-2) + ':' + ('0' + remainingSeconds).slice(-2);
  }

  addDefaultValuesAndColons(inputValue) {
    inputValue = inputValue.split('.')[0]
    inputValue = inputValue.replaceAll(":", "")

    const length = inputValue.length;

    if (length === 6) {
      // Convert 6-digit input to HH:MM:SS format
      inputValue = inputValue.slice(0, 2) + ':' + inputValue.slice(2, 4) + ':' + inputValue.slice(4);
    } else if (length < 6) {
      // Add default values to the left until the total length is 6
      inputValue = '0'.repeat(6 - length) + inputValue;

      // Add colons after 2nd and 4th positions
      inputValue = inputValue.slice(0, 2) + ':' + inputValue.slice(2, 4) + ':' + inputValue.slice(4);
    }

    return inputValue;
  }

  // Function to get the current cursor position
  getCursorPosition() {
    return this.element.selectionStart;
  }

  // Function to set the cursor position
  setCursorPosition(position) {
    this.element.setSelectionRange(position, position);
  }
}